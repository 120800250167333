import { toastHandler } from './toast.util';

function requestErrorToastHandler(
  error: any,
  defaultMessage: string = 'Não foi possível realizar essa ação, caso o problema persista, contate o suporte.',
) {
  if (Array.isArray(error)) {
    return error.map((message) => toastHandler({ message: message, type: 'error' }));
  }

  if (typeof error === 'string') {
    return toastHandler({ message: error, type: 'error' });
  }

  return toastHandler({ message: defaultMessage, type: 'error' });
}

export { requestErrorToastHandler };
