import { z, ZodSchema } from "zod";

/** Validador para nomes, permitindo apenas letras e espaços, com tamanho entre 2 e 100 caracteres. */
export const zodName = z
  .string()
  .trim()
  .min(2, "O nome deve ter pelo menos 2 caracteres.")
  .max(100, "O nome pode ter no máximo 100 caracteres.")
  .regex(/^[a-zA-Zà-úÀ-Ú\s]+$/, "O nome deve conter apenas letras e espaços.");

/** Validador para nomes de empresas, permitindo letras, números, espaços e caracteres especiais, */
export const zodCompanyName = z
  .string()
  .trim()
  .min(2, "O nome da empresa deve ter pelo menos 2 caracteres.")
  .max(256, "O nome da empresa pode ter no máximo 256 caracteres.");

/** Validador para emails, garantindo formato válido. */
export const zodEmail = z.string().email("Formato de email inválido.").trim();

/** Validador para UUIDs, garantindo formato válido. */
export const zodUUID = z.string().uuid("O ID deve estar no formato UUID.");

/** Validador para gêneros, aceitando apenas "M", "F" ou "O". */
export const zodGender = z.enum(["M", "F", "O"], {
  message: "O gênero deve ser um dos seguintes: M, F ou O.",
});

/** Validador para senhas, exigindo entre 8 e 128 caracteres, com requisitos específicos. */
export const zodPassword = z
  .string()
  .min(8, "A senha deve ter pelo menos 8 caracteres.")
  .max(128, "A senha pode ter no máximo 128 caracteres.")
  .regex(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula.")
  .regex(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula.")
  .regex(/[0-9]/, "A senha deve conter pelo menos um número.")
  .regex(
    /[@$!%*?&]/,
    "A senha deve conter pelo menos um caractere especial (@, $, !, %, *, ?, &)."
  );

/** Validador para idades, permitindo apenas números inteiros entre 18 e 120 anos. */
export const zodAge = z
  .string()
  .trim()
  .refine(
    (value) => !isNaN(Number(value)),
    "A idade deve ser um número válido."
  )
  .transform((value) => Number(value))
  .pipe(
    z
      .number()
      .int("A idade deve ser um número inteiro.")
      .min(18, "A idade mínima permitida é 18 anos.")
      .max(120, "A idade máxima permitida é 120 anos.")
  );

/** Validador para números de telefone no formato internacional. */
export const zodPhone = z
  .string()
  .trim()
  .regex(
    /^\+?[1-9]\d{1,14}$/,
    "O número de telefone deve estar no formato internacional (e.g., +5511999999999)."
  );

/** Validador para CEPs brasileiros no formato 12345-678 ou 12345678. */
export const zodCep = z
  .string()
  .trim()
  .regex(
    /^\d{5}-?\d{3}$/,
    "O CEP deve estar no formato 12345-678 ou 12345678."
  );

/** Validador para URLs. */
export const zodUrl = z.string().trim().url("A URL fornecida é inválida.");

/** Validador para datas no formato ISO (YYYY-MM-DD). */
export const zodDate = z
  .string()
  .trim()
  .regex(
    /^\d{4}-\d{2}-\d{2}$/,
    "A data deve estar no formato ISO (YYYY-MM-DD)."
  );

/** Validador para datas e horas no formato ISO (YYYY-MM-DDTHH:mm:ss.SSSZ). Exemplo: 2021-12-31T23:59:59.999Z */
export const zodDateTime = z
  .string()
  .trim()
  .regex(
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    "A data e hora devem estar no formato ISO (YYYY-MM-DDTHH:mm:ss.SSSZ)."
  );

/** Validador para valores monetários, garantindo números não negativos. */
export const zodCurrency = z
  .string()
  .trim()
  .refine(
    (value) => !isNaN(Number(value)),
    "O valor monetário deve ser um número válido."
  )
  .transform((value) => parseFloat(value))
  .refine((value) => value >= 0, "O valor monetário não pode ser negativo.");

/** Validador para valores booleanos, aceitando "true" ou "false" como strings. */
export const zodBoolean = z
  .union([z.boolean(), z.string().trim()])
  .transform((value) => (typeof value === "string" ? value === "true" : value));

/** Validador para números inteiros, com parsing automático de strings. */
export const zodInteger = ({
  message = "O valor deve ser um número inteiro válido.",
}: {
  message: string;
}) =>
  z
    .string()
    .trim()
    .refine(
      (value) => !isNaN(Number(value)) && Number.isInteger(Number(value)),
      message
    )
    .transform((value) => parseInt(value, 10));

/** Validador para números flutuantes, com parsing automático de strings. */
export const zodFloat = z
  .string()
  .trim()
  .refine(
    (value) => !isNaN(Number(value)),
    "O valor deve ser um número válido."
  )
  .transform((value) => parseFloat(value));

/** Validador para listas de strings separadas por vírgulas. */
export const zodStringArray = z
  .string()
  .trim()
  .transform((value) => value.split(",").map((item) => item.trim()))
  .refine(
    (array) => array.every((item) => item.length > 0),
    "Cada item na lista deve ser uma string válida."
  );

/**
 * Validador para enums, aceitando apenas valores permitidos.
 *
 * @param allowedValues Lista de valores permitidos no enum.
 */
export const zodEnum = (allowedValues: string[]) =>
  z
    .string()
    .trim()
    .refine(
      (value) => allowedValues.includes(value),
      `O valor deve ser um dos seguintes: ${allowedValues.join(", ")}.`
    );

/**
 * Função genérica para integrar Zod com validadores do Mongoose.
 *
 * @param schema O esquema Zod que será usado para validação.
 * @returns Uma função de validação para o Mongoose.
 */
export const zodValidator = (schema: ZodSchema<any>) => {
  return (value: any) => {
    try {
      schema.parse(value); // Valida o valor usando o Zod
      return true; // Validação bem-sucedida
    } catch (e: any) {
      console.error("Erro de validação:", e.errors || e.message); // Log do erro
      return false; // Falha na validação
    }
  };
};

/**
 * Validador para endereços, com campos detalhados para cidade, país, bairro, número, estado, rua, CEP e localização
 * geográfica.
 *
 * @property city {string} Nome da cidade. Campo obrigatório e não vazio.
 * @property complement {string | null | undefined} Complemento do endereço. Campo opcional, limitado a 256 caracteres.
 * @property country {string} Nome do país. Campo obrigatório, com valor padrão "BR".
 * @property neighbourhood {string} Nome do bairro. Campo obrigatório e não vazio.
 * @property number {number} Número do endereço. Campo obrigatório, deve ser numérico.
 * @property state {string} Nome do estado. Campo obrigatório e não vazio.
 * @property street {string} Nome da rua. Campo obrigatório e não vazio.
 * @property zipCode {string} CEP do endereço. Valida formato brasileiro (12345-678 ou 12345678).
 * @property placeId {string | null | undefined} Identificador do lugar (place ID). Campo opcional.
 * @property location {object | null | undefined} Localização geográfica com tipo "Point" e coordenadas (latitude e
 *   longitude). Campo opcional.
 */
export const zodAddress = z.object({
  city: z
    .string({
      message: "Cidade é obrigatória",
    })
    .trim(),
  complement: z.string().max(256).nullable().optional(),
  country: z
    .string({
      message: "País é obrigatório",
    })
    .trim(),
  neighbourhood: z
    .string({
      message: "Bairro é obrigatório",
    })
    .trim(),
  number: zodInteger({
    message:
      "Número do endereço é obrigatório e deve estar no formato numérico.",
  }),
  state: z
    .string({
      message: "Estado é obrigatório",
    })
    .trim(),
  street: z
    .string({
      message: "Rua é obrigatória",
    })
    .trim(),
  zipCode: zodCep,
  placeId: z.string().trim().nullable().optional(),
  location: z
    .object({
      type: z.literal("Point"),
      coordinates: z.tuple([z.number(), z.number()]),
    })
    .nullable()
    .optional(),
});

