import { Plan } from '../../../../types/v2/plan';
import { api } from './api';

type GetAllPlansQuery = {};

type GetAllPlansResponse = {};

type GetPlanByIdResponse = {};

type GetPlanByContractTypeResponse = Plan[] & {};

type CreatePlanDto = {};

type CreatePlanResponse = {};

type UpdatePlanDto = {};

type UpdatePlanResponse = {};

type DeletePlanResponse = {};

function postCreatePlanService(body: CreatePlanDto): Promise<CreatePlanResponse> {
  return api.post(`/plans`, body);
}

function patchUpdatePlanService(planId: string, body: UpdatePlanDto): Promise<UpdatePlanResponse> {
  return api.patch(`/plans/${planId}`, body);
}

function deletePlansService(planId: string): Promise<DeletePlanResponse> {
  return api.delete(`/plans/${planId}`);
}

function getAllPlansService(query?: GetAllPlansQuery): Promise<GetAllPlansResponse> {
  return api.get(`/plans`, { params: query });
}

function getPlansByContractTypeService(type: string): Promise<GetPlanByContractTypeResponse> {
  return api.get(`/plans/only/${type}`);
}

function getPlanByIdService(planId: string): Promise<GetPlanByIdResponse> {
  return api.get(`/plans/${planId}`);
}

export { getAllPlansService, getPlansByContractTypeService, getPlanByIdService, postCreatePlanService, patchUpdatePlanService, deletePlansService };
