import { SelectOption } from '../../../../types/v2/global';
import { useEffect, useState } from 'react';
import { getAllUnitsService, UnitInList } from '../services/unit.service';

export function useUnitsForCombo(): {
  unitOptions: SelectOption[];
  units: UnitInList[];
  isFetching: boolean;
} {
  const [unitOptions, setUnitOptions] = useState<SelectOption[]>([]);
  const [units, setUnits] = useState<UnitInList[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchUnitsForCombo(search: string) {
    setIsFetching(true);
    try {
      const response = await getAllUnitsService({
        search,
        limit: 0,
        page: 0,
        disablePagination: true,
        lean: true,
      });

      const newUnitsOptions: SelectOption[] = response.docs.map((unit) => ({
        label: unit.name,
        value: unit._id,
      }));

      setUnits(response.docs);
      setUnitOptions(newUnitsOptions);

      return newUnitsOptions;
    } catch (error) {
      return [] as SelectOption[];
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchUnitsForCombo('');
  }, []);

  return { unitOptions, units, isFetching };
}
