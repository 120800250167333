import { api } from './api';
import { Contract, ContractProperties, ContractStatus, ContractType } from '../../../../types/v2/contract';

export type CreateContractDto = {
  companyId: string;
  name: string;
  status: ContractStatus;
  type: ContractType;
  properties: ContractProperties;
};

type CreateContractResponse = {};

export type UpdateContractDto = {};

type UpdateContractResponse = {
  companyId: string;
  name?: string;
  status?: ContractStatus;
  type?: ContractType;
  properties?: ContractProperties;
};

type GetAllContractsQuery = {
  companyId?: string;
};

type GetAllContractsResponse = Contract[] & {};

type GetContractByIdResponse = {};

type DeleteContractResponse = {};

function postCreateContractService(body: CreateContractDto): Promise<CreateContractResponse> {
  return api.post('/contracts', body);
}

function patchUpdateContractService(contractId: string, body: UpdateContractDto): Promise<UpdateContractResponse> {
  return api.patch(`/contracts/${contractId}`, body);
}

function getAllContractsService(query: GetAllContractsQuery): Promise<GetAllContractsResponse> {
  return api.get('/v2/companies/contracts', { params: query });
}

function getContractByIdService(contractId: string): Promise<GetContractByIdResponse> {
  return api.post(`/contracts/${contractId}`);
}

function deleteContractService(contractId: string): Promise<DeleteContractResponse> {
  return api.delete(`/contracts/${contractId}`);
}

export { postCreateContractService, patchUpdateContractService, getAllContractsService, getContractByIdService, deleteContractService };
