import { jwtDecode } from 'jwt-decode';
import { SelectOption } from '../../../../types/v2/global';
import { UserFromToken, UserUnit } from '../../../../types/v2/user';
import { mapContractTypeDescription, mapRoleToDescription, mapUnitTypeToBadgeVariant } from './mapper.util';
import { getDecodedToken } from './token.util';
import { Badge } from '@eyecarehealth/ds-aquilae-react';
import { ContractType } from '../../../../types/v2/contract';

function setUnitAsEmployeeUtil(unit: UserUnit) {
  localStorage.setItem('unitAsEmployee', JSON.stringify(unit));
}

function setUnitsAsAdminUtil(units: UserUnit[]) {
  localStorage.setItem('unitsAsAdmin', JSON.stringify(units));
}

function setUnitsAsDoctorUtil(units: UserUnit[]) {
  localStorage.setItem('unitsAsDoctor', JSON.stringify(units));
}

function getUnitInSessionUtil(): UserUnit {
  const unitInSession = JSON.parse(localStorage.getItem('unitInSession') || '{}');
  return unitInSession;
}

function getUserViewTypeUtil(): string {
  const userViewType = JSON.parse(localStorage.getItem('userViewType') || '{}');
  return userViewType;
}

function getRolesForComboUtil(token?: string): SelectOption[] {
  const decodedToken: UserFromToken = token ? jwtDecode(token) : getDecodedToken();

  return decodedToken.roles.map((role) => ({ label: mapRoleToDescription[role], value: role }));
}

function getAllUserUnitsUtil(): UserUnit[] {
  const unitsAsAdmin = getUnitsAsAdminUtil();
  const unitsAsDoctor = getUnitsAsDoctorUtil();
  const unitAsEmployee = getUnitAsEmployeeUtil();

  return [...unitsAsAdmin, ...unitsAsDoctor, unitAsEmployee];
}

function getUnitAsEmployeeUtil(): UserUnit | null {
  const unitAsEmployee = JSON.parse(localStorage.getItem('unitAsEmployee') || '{}');
  if (unitAsEmployee?._id) return unitAsEmployee;
  return null;
}

function getUnitsAsAdminUtil(): UserUnit[] {
  const unitsAsAdmin = JSON.parse(localStorage.getItem('unitsAsAdmin') || '[]');
  if (unitsAsAdmin) return unitsAsAdmin;
  return [];
}

function getUnitsAsDoctorUtil(): UserUnit[] {
  const unitsAsDoctor = JSON.parse(localStorage.getItem('unitsAsDoctor') || '[]');
  if (unitsAsDoctor) return unitsAsDoctor;
  return [];
}

function ContractTypeBadge({ contractType }: { contractType: ContractType | null }) {
  return (
    <Badge className="!rounded-1 h-auto px-1 py-0 text-[10px] tracking-normal" variant={mapUnitTypeToBadgeVariant[contractType]}>
      {contractType ? mapContractTypeDescription[contractType] : 'Sem contrato'}
    </Badge>
  );
}

function getUnitsAsEmployeeForComboUtil(): SelectOption[] {
  const unit = getUnitAsEmployeeUtil();
  if (unit?._id)
    return [
      {
        label: unit.name,
        value: unit._id,
        icon: <ContractTypeBadge contractType={unit.enabledContractsDetails?.type || null} />,
      },
    ];
  return [];
}

function getUnitsAsAdminForComboUtil(): SelectOption[] {
  const units = getUnitsAsAdminUtil();
  if (units)
    return units.map((unit) => ({
      label: unit.name,
      value: unit._id,
      icon: <ContractTypeBadge contractType={unit.enabledContractsDetails?.type || null} />,
    }));
  return [];
}

function getUnitsAsDoctorForComboUtil(): SelectOption[] {
  const units = getUnitsAsDoctorUtil();
  if (units)
    return units.map((unit) => ({
      label: unit.name,
      value: unit._id,
      icon: <ContractTypeBadge contractType={unit.enabledContractsDetails?.type || null} />,
    }));
  return [];
}

function getNestedUnitsForComboUtil(): SelectOption[] {
  const unitsAsAdmin = getUnitsAsAdminForComboUtil();
  const unitsAsDoctor = getUnitsAsDoctorForComboUtil();
  const unitAsEmployee = getUnitsAsEmployeeForComboUtil();

  const nestedUnits = [...unitAsEmployee, ...unitsAsAdmin, ...unitsAsDoctor];

  return nestedUnits;
}

function userHasMoreThanOneUnitUtil() {
  const unitsAsAdmin = getUnitsAsAdminUtil();
  const unitsAsDoctor = getUnitsAsDoctorUtil();
  const unitAsEmployee = getUnitAsEmployeeUtil();

  if (unitsAsAdmin.length > 1) return true;

  if (unitsAsDoctor.length > 1) return true;

  if (unitAsEmployee?._id && unitsAsAdmin.length > 0) return true;

  if (unitAsEmployee?._id && unitsAsDoctor.length > 0) return true;

  if (unitsAsAdmin.length > 0 && unitsAsDoctor.length > 0) return true;

  return false;
}

function getUserUniqueUnitUtil(): UserUnit {
  const unitAsEmployee = getUnitAsEmployeeUtil();
  const unitsAsAdmin = getUnitsAsAdminUtil();
  const unitsAsDoctor = getUnitsAsDoctorUtil();

  if (unitAsEmployee?._id) return unitAsEmployee;

  if (unitsAsAdmin.length > 0) return unitsAsAdmin[0];

  if (unitsAsDoctor.length > 0) return unitsAsDoctor[0];

  throw new Error('User has no unit');
}

export {
  getUnitInSessionUtil,
  getUnitsAsAdminUtil,
  getUnitsAsDoctorUtil,
  getUnitAsEmployeeUtil,
  getUserViewTypeUtil,
  getUnitsAsEmployeeForComboUtil,
  getUnitsAsAdminForComboUtil,
  getUnitsAsDoctorForComboUtil,
  getNestedUnitsForComboUtil,
  getRolesForComboUtil,
  userHasMoreThanOneUnitUtil,
  getUserUniqueUnitUtil,
  getAllUserUnitsUtil,
  setUnitAsEmployeeUtil,
  setUnitsAsAdminUtil,
  setUnitsAsDoctorUtil,
};
