import { EExam, EExamStatus, ETestType, ExamResponse } from '../../../../types/v2/exam';
import { getDecodedToken } from './token.util';

const mapModuleToExam: Record<string, string> = {
  [EExam.TRIAGEM]: 'screening',
  [EExam.ACUIDADE]: 'visualAcuity',
  [EExam.ISHIHARA]: 'ishihara',
  [EExam.ACUIDADE_PERTO]: 'nearVisualAcuity',
};

// Módulos válidos para verificação
const VALID_MODULES = [EExam.ACUIDADE, EExam.TRIAGEM, EExam.ISHIHARA, EExam.ACUIDADE_PERTO];

function isExamDone(exams: ExamResponse[], examType: ETestType, minDays?: number) {
  const MIN_DAYS_TO_REPEAT = minDays || 30;
  const limitDate = new Date();
  limitDate.setDate(limitDate.getDate() - MIN_DAYS_TO_REPEAT);

  // Otimização: encontrar apenas o exame mais recente que atenda aos critérios
  for (const exam of exams) {
    if (exam.testType === examType && exam.status === EExamStatus.COMPLETED && new Date(exam.createdAt) > limitDate) {
      return true;
    }
  }

  return false;
}

function doneExams(exams: ExamResponse[], minDays?: number): string[] {
  const decodedToken = getDecodedToken();
  const doneExams = new Set<string>(); // Usando Set para evitar duplicatas automaticamente
  const MIN_DAYS_TO_REPEAT = minDays || 30;
  const limitDate = new Date();
  limitDate.setDate(limitDate.getDate() - MIN_DAYS_TO_REPEAT);

  // Pré-processamento: criar um mapa de exames por tipo para acesso mais rápido
  const examsByType = new Map<string, ExamResponse>();

  // Encontrar o exame mais recente e completo para cada tipo
  for (const exam of exams) {
    if (exam.status === EExamStatus.COMPLETED) {
      const currentExam = examsByType.get(exam.testType);
      if (!currentExam || new Date(exam.createdAt) > new Date(currentExam.createdAt)) {
        examsByType.set(exam.testType, exam);
      }
    }
  }

  // Verificar módulos permitidos
  for (const module of decodedToken.modules) {
    if (VALID_MODULES.includes(module as EExam)) {
      const testType = mapModuleToExam[module];
      const exam = examsByType.get(testType);

      if (exam && new Date(exam.createdAt) > limitDate) {
        doneExams.add(testType);
      }
    }
  }

  return Array.from(doneExams);
}

function getLastExamDoneByType(exams: ExamResponse[], examType: ETestType): ExamResponse | null {
  const sameExams = exams.filter((exam) => exam.testType === examType && exam.status === EExamStatus.COMPLETED);
  if (sameExams.length > 0) {
    return sameExams.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
  }
  return null;
}

function canRepeatExam(exams: ExamResponse[], examType: ETestType, minDays?: number): boolean {
  const exam = getLastExamDoneByType(exams, examType);
  if (!exam) return true;
  const MIN_DAYS_TO_REPEAT = minDays || 30;
  const limitDate = new Date();
  limitDate.setDate(limitDate.getDate() - MIN_DAYS_TO_REPEAT);
  return new Date(exam.createdAt) < limitDate;
}

/**
 * Calcula o tamanho do símbolo para um teste de acuidade visual usando a fórmula de Snellen.
 *
 * @param targetAv - Acuidade Visual desejada em pés (por exemplo, 20/40 seria representado como 40)
 * @param distance - Distância de trabalho do optotipo em centímetros
 * @returns O tamanho do símbolo em milímetros
 */

function applySnellenFormula(targetAv: number, distance: number): number {
  // size = Tamanho da letra.
  // tagetAv = Acuidade Visual desejada em pés.
  // distance = Distância de trabalho do optotipo em centímetros.
  // 20 = Constante em pés (equivalente a 6 metros).
  // 0,00029 = tangente de 1' (um minuto).
  // 5’ = ângulo visual de cinco minutos.
  // 1000 = converter metro para milímetro.
  const size = (targetAv * (distance / 100)) / 20;
  return size * 0.00029 * 5 * 1000;
}

export { isExamDone, doneExams, getLastExamDoneByType, canRepeatExam, applySnellenFormula };
