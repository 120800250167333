import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        login_saudacao: "Welcome to the BV",
        perfil_saudacao: "Profile Salutation",
      },
    },
    pt: {
      translation: {
        login_saudacao: "Bem vindo ao BV",
        perfil_saudacao: "Perfil saudações",
      },
    },
    es: {
      translation: {
        login_saudacao: "Bem vindo ao BV",
        perfil_saudacao: "Saludos de perfil",
      },
    },
  },
  lng: navigator.language, // Default language
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
