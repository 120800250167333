import { ApiQueryBase } from './api';
import { Company } from './company';
import { Plan } from './plan';
import { User } from './user';

export type ExamResponse<T = ScreeningResponseDetails | VisualAcuityResponseDetails | IshiharaResponseDetails | NearVisualAcuityDetails> = {
  _id: string;
  id: string;
  patientId: string;
  lastStep?: string | null;
  response: T;
  status: EExamStatus;
  testType: ETestType;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
};

export enum EExam {
  TRIAGEM = '1',
  ACUIDADE = '2',
  ISHIHARA = '3',
  TELEMEDICINA = '4',
  DESCONTO_EM_ÓTICAS = '5',
  CONCIERGE = '6',
  TELEOFTALMOLOGIA = '7',
  PLANO_DE_COMUNICACAO = '8',
  CONTEUDO_ESPECIAL_DE_SAUDE = '9',
  CONSULTORIO_IN_COMPANY = '10',
  RENOVACAO_DE_RECEITA_DE_ÓTICAS = '11',
  ACUIDADE_PERTO = '12',
}

export const enum ETestType {
  ACUIDADE_PERTO = 'nearVisualAcuity',
  ACUIDADE = 'visualAcuity',
  TRIAGEM = 'screening',
  ISHIHARA = 'ishihara',
  TELEMEDICINA = 'telemedicine',
}

export const enum EExamStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
}

export type ScreeningResponseDetails = {
  epiHasDegreeCorrection: string;
  eyeConditions: Array<string>;
  eyeDiscomfort: Array<string>;
  glassesOrLenses: boolean;
  hasEPI: string;
  hasLastPrescription: string;
  informDegree: {
    cilindrico_D: string;
    cilindrico_E: string;
    deleted_at: string;
    eixo_D: string;
    eixo_E: string;
    esferico_D: string;
    esferico_E: string;
    type: string;
  };
  lastVisitInMonths: number;
  medicinesList: Array<string>;
  pathologiesUnderTreatment: Array<string>;
  usesContinuousMeds: string;
  seeWellWithoutGlasses: boolean;

  // Found on DB
  alreadyHadPrescription: string;
  canInformPrescriptionDegree: string;
  hasEPISameDegreeAsGlasses: string;
  informDegreeEPI: boolean;
};

export type VisualAcuityResponseDetails = {
  right:
    | {
        acuity: string;
        interactions?: number;
        correctAnswers?: number;
        incorrectAnswers?: number;
        status?: 'warning' | 'success';
      }
    | 'SPL';
  left:
    | {
        acuity: string;
        interactions?: number;
        correctAnswers?: number;
        incorrectAnswers?: number;
        status?: 'warning' | 'success';
      }
    | 'SPL';
  initialForm: {
    isUsingGlassesOrLensesDuringTheExam: boolean;
    useGlassesOrLenses: string;
    satisfiedWithCurrentGlasses: boolean;
    howToImprove: string;
  };
};

export type NearVisualAcuityDetails = {
  right?:
    | {
        acuity: string;
        classification: string;
        interactions: number;
        correctAnswers: number;
        incorrectAnswers: number;
        status?: 'warning' | 'success';
      }
    | 'SPL';
  left?:
    | {
        acuity: string;
        classification: string;
        interactions: number;
        correctAnswers: number;
        incorrectAnswers: number;
        status?: 'warning' | 'success';
      }
    | 'SPL';
  both?: {
    acuity: string;
    classification: string;
    interactions: number;
    correctAnswers: number;
    incorrectAnswers: number;
    status?: 'warning' | 'success';
  };
  isUsingGlassesOrLensesDuringTheExam: boolean;
  selectedEquipment: 'glasses' | 'contact' | 'epi' | 'none';
};

export type IshiharaResponseDetails = {
  hits: number;
  redGreenDeficiency: boolean;
  redDeficiency: boolean;
  greenDeficiency: boolean;
  miss: number;
  hitsList: number[];
  missList: number[];
  diagnosysLabel: string;
  answers: {
    screen1: string;
    screen2: string;
    screen3: string;
    screen4: string;
    screen5: string;
    screen6: string;
    screen7: string;
    screen8: string;
    screen9: string;
    screen10: string;
    screen11: string;
    screen12: string;
    screen13: string;
    screen14: string;
  };
};

export type Appointment = {
  id: string;
  clinic_id: string;
  professional_id: string;
  patient_id: string;
  appointment_integration_code: any;
  health_plan_id: any;
  plan_id: any;
  room_id: any;
  equipment_id: any;
  cns: any;
  type: string;
  overlap_reason: any;
  start_datetime: string;
  end_datetime: string;
  procedures_value: any;
  discount: any;
  discount_prefix: any;
  total_value: any;
  ordem_agenda: any;
  telemedicine: boolean;
  telemedicine_link: string;
  description: any;
  status: EAppointmentStatus;
  status_time: any;
  pending_warehouse: boolean;
  pending_financial: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
};

export enum EAppointmentStatus {
  ATTEND = 'ATTEND',
  SCHEDULED = 'SCHEDULED',
  FINISHED = 'FINISHED',
  CONFIRMED = 'CONFIRMED',
  CONFIRMED_BY_PATIENT = 'CONFIRMED_BY_PATIENT',
  MISSED = 'MISSED',
  CANCELLED = 'CANCELLED',
  CANCELLED_BY_PATIENT = 'CANCELLED_BY_PATIENT',
  WAITING = 'WAITING',
  HAPPENING = 'HAPPENING',
  EXAMINATING = 'EXAMINATING',
  DILATING = 'DILATING',
  PRE_FINISHED = 'PRE_FINISHED',
  IN_PRE_CONSULTATION = 'IN_PRE_CONSULTATION',
}

export type UserDoingExamFromRH = User & {
  rootCompany: Company;
  responses: ExamResponse[];
  plan: Plan;
};

//API
export type GetResponseByIdService = ExamResponse;

export type GetResponsesByPatientIdQuery = ApiQueryBase;

export type GetResponsesByPatientIdResponse = ExamResponse[];

export type CreateResponseQuery = {};

export type CreateResponseDto = {
  patientId: string;
  response: any;
  status: string;
  testType: ETestType;
};

export type UpdateResponseDto = {
  patientId?: string;
  lastStep?: string;
  response?: any;
  status?: string;
  testType?: ETestType;
};

export type UpdateResponseEvidencesDto = {
  start: string;
  halfway: string;
  end: string;
};

export type UpdateResponseEvidencesResponse = {
  id: string;
  responseId: string;
  start: string;
  halfway: string;
  end: string;
};

export type UpdateResponseQuery = {};

export type CreateResponseResponse = ExamResponse;
