import { SelectOption } from '../../../../types/v2/global';
import { useEffect, useState } from 'react';
import { getAllContractsService } from '../services/contract.service';
import { Contract } from '../../../../types/v2/contract';

export function useContractsForCombo(
  companyId: string,
  removeContracts: string[] = [],
): {
  contractOptions: SelectOption[];
  contracts: Contract[];
  isFetching: boolean;
} {
  const [contractOptions, setContractOptions] = useState<SelectOption[]>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchContractsForCombo(search: string) {
    setIsFetching(true);
    try {
      const response = await getAllContractsService({});

      setContracts(response);
      const newContractsOptions: SelectOption[] = response.map((contract) => ({
        label: contract.name,
        value: contract._id,
      }));

      if (removeContracts.length > 0) {
        return setContractOptions(newContractsOptions.filter((contract) => !removeContracts.includes(contract.value)));
      } else {
        setContractOptions(newContractsOptions);
      }

      return newContractsOptions;
    } catch (error) {
      return [] as SelectOption[];
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchContractsForCombo('');
  }, []);

  return { contractOptions, contracts, isFetching };
}
