import { SelectOption } from '../../../../types/v2/global';
import { useEffect, useState } from 'react';
import { getAllSubsidiariesService } from '../services/subsidiary.service';

export function useSubsidiariesForCombo(): {
  subsidiaryOptions: SelectOption[];
  isFetching: boolean;
} {
  const [subsidiaryOptions, setSubsidiaryOptions] = useState<SelectOption[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchSubsidiariesForCombo(search: string) {
    setIsFetching(true);
    try {
      const subsidiaries = await getAllSubsidiariesService({
        search,
        page: 0,
        limit: 0,
        disablePagination: true,
      });

      const newSubsidiriesOptions: SelectOption[] = subsidiaries.docs.map((subsidiary) => ({
        label: subsidiary.name,
        value: subsidiary._id,
      }));

      setSubsidiaryOptions(newSubsidiriesOptions);

      return newSubsidiriesOptions;
    } catch (error) {
      return [] as SelectOption[];
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchSubsidiariesForCombo('');
  }, []);

  return { subsidiaryOptions, isFetching };
}
