export enum ECookie {
  ACCESS_TOKEN = 'access_token',
  USER = 'user',
  ROLES = 'roles',
  VIEW_TYPE = 'view_type',
  COMPANY = 'company',
  ONBOARDING = 'onboarding',
  PIXEL_RATIO = 'pixelRatio',
  CUSTOM_DISTANCE = 'customDistance',
  SCREEN_CALIBRATION = 'screen_calibration',
}

export const setCookie = (key: ECookie, value: string, expires = 1) => {
  const date = new Date();
  date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
  document.cookie = `_bv_${key}=${value};expires=${date.toUTCString()};path=/`;
};

export const getCookie = (key: ECookie) => {
  const name = `_bv_${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (key: ECookie) => {
  document.cookie = `_bv_${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const isAuthenticated = (): boolean => {
  return Boolean(getCookie(ECookie.ACCESS_TOKEN) && getCookie(ECookie.USER));
};

export const setAccessToken = (accessToken: string) => {
  window.localStorage.setItem('access_token', JSON.stringify(accessToken));
  setCookie(ECookie.ACCESS_TOKEN, accessToken);
};

export const setCurrentUser = (user: any): void => {
  const roles = user.roles;
  delete user.roles;
  setCookie(ECookie.USER, JSON.stringify(user));
  setCookie(ECookie.ROLES, JSON.stringify(roles));
};

export const getAccessToken = (): string | null => {
  const accessTokenFromLocalStorage = window.localStorage.getItem('access_token');
  const accessTokenFromCookie = getCookie(ECookie.ACCESS_TOKEN);
  if (accessTokenFromCookie) return accessTokenFromCookie;
  if (accessTokenFromLocalStorage) return JSON.parse(accessTokenFromLocalStorage);
  return null;
};

export const clearAuthCookies = () => {
  Object.values(ECookie).forEach((cookie) => {
    ![ECookie.PIXEL_RATIO, ECookie.CUSTOM_DISTANCE, ECookie.SCREEN_CALIBRATION].includes(cookie) && deleteCookie(cookie);
  });
};

export const setPixelRatio = (pixelRatio: number) => {
  setCookie(ECookie.PIXEL_RATIO, pixelRatio.toString());
};

export const getPixelRatio = (): number => {
  return parseFloat(getCookie(ECookie.PIXEL_RATIO) || '1');
};

export const setCustomDistance = (customDistance: number) => {
  setCookie(ECookie.CUSTOM_DISTANCE, customDistance.toString());
};

export const getCustomDistance = (): number => {
  return parseInt(getCookie(ECookie.CUSTOM_DISTANCE) || '40');
};
