import { api } from './api';
import { UserHealthData } from '../../../../types/v2/healthData';

export type CreateUserHealthDataDto = {
  hasLeftEye: boolean;
  hasRightEye: boolean;
  userId: string;
};
type CreateUserHealthDataResponse = {
  id: string;
  hasLeftEye: boolean;
  hasRightEye: boolean;
  userId: string;
};
function postCreateUserHealthDataService(body: CreateUserHealthDataDto): Promise<CreateUserHealthDataResponse> {
  return api.post(`/v2/user-health-data/`, body);
}

type GetAllUsersHealthDataResponse = UserHealthData[];
function getAllUsersHealthDataService(): Promise<GetAllUsersHealthDataResponse> {
  return api.get(`/v2/user-health-data/`);
}

type GetUserHealthDataByIdResponse = UserHealthData;
function getUserHealthDataByIdService(userId: string): Promise<GetUserHealthDataByIdResponse> {
  return api.get(`/v2/user-health-data/${userId}`);
}

export type UpdateUserHealthDataDto = {
  hasLeftEye: boolean;
  hasRightEye: boolean;
};
type UpdateUserHealthDataResponse = UserHealthData;
function patchUpdateUserHealthDataService(id: string, body: UpdateUserHealthDataDto): Promise<UpdateUserHealthDataResponse> {
  return api.patch(`/v2/user-health-data/${id}`, body);
}

type DeleteUserHealthDataResponse = { message: string };
function deleteUserHealthDataService(id: string): Promise<DeleteUserHealthDataResponse> {
  return api.delete(`/v2/user-health-data/${id}`);
}

export {
  postCreateUserHealthDataService,
  getAllUsersHealthDataService,
  getUserHealthDataByIdService,
  patchUpdateUserHealthDataService,
  deleteUserHealthDataService,
};
