import { useCompanyTypesForCombo } from './useCompanyTypesForCombo';
import { useContractsForCombo } from './useContractsForCombo';
import { useCountiresForCombo } from './useCountriesForCombo';
import { useSubsidiariesForCombo } from './useSubsidiariesForCombo';
import { useUnitsForCombo } from './useUnitsForCombo';
import { useJobPositionsForCombo } from './useJobPositionsForCombo';

export default {
  useCompanyTypesForCombo,
  useUnitsForCombo,
  useContractsForCombo,
  useSubsidiariesForCombo,
  useCountiresForCombo,
  useJobPositionsForCombo,
};
