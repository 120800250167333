import axios from 'axios';
import { clearAuthCookies, getAccessToken } from '../utils/cookies.util';
import { requestErrorToastHandler } from '../utils/request.util';

export const api = axios.create({
  baseURL: process.env.BV_API_URL,
});

export const apiBI = axios.create({
  baseURL: process.env.VITE_PUBLIC_BI_API_URL,
  headers: {
    Accept: 'application/json',
  },
});

api.interceptors.request.use(function (config) {
  const ACCESS_TOKEN: string | null = getAccessToken();

  if (ACCESS_TOKEN) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    });
  }

  return config;
});

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (err) {
    if (err.response && [401, 403].includes(+err.response.status)) {
      clearAuthCookies();
      window.localStorage.clear();
      window.location.href = '/login';
    }

    if (err?.response?.data?.messages) {
      requestErrorToastHandler(err.response.data.messages);
      return Promise.reject(err.response.data.messages);
    }

    if (err?.response?.data?.message) {
      requestErrorToastHandler(err.response.data.message);
      return Promise.reject(err.response.data.message);
    }

    return Promise.reject('Não foi possível realizar essa ação, caso o problema persista, contate o suporte.');
  },
);
