import { I18nextProvider, useTranslation } from 'react-i18next';
import api from './api/config';
import * as cookies from './utils/cookies.util';
import i18n from './utils/internacionalization.util';
import { toastHandler, toastPromiseHandler } from './utils/toast.util';
import emitter from './utils/events.util';
import * as dump from './utils/dump.util';
import * as token from './utils/token.util';
import * as mask from './utils/mask.util';
import * as file from './utils/file.util';
import * as request from './utils/request.util';
import * as device from './utils/device.util';
import * as location from './utils/location.util';
import * as mapper from './utils/mapper.util';
import * as session from './utils/session.util';
import * as screen from './utils/screen.util';
import * as exams from './utils/exams.util';
import hooks from './hooks';
import * as zod from './utils/zod';
import * as services from './services';
export default {
  api,
  services,
  cookies,
  dump,
  token,
  mask,
  file,
  request,
  device,
  location,
  mapper,
  session,
  zod,
  screen,
  exams,
  //events
  emitter,

  //hooks
  hooks,

  //internacionalization
  internacionalization: {
    useTranslation,
    I18nextProvider,
    i18n,
  },

  //dump
  toastHandler,
  toastPromiseHandler,
};

//trigger pipeline4
