import { api } from './api';
import { Review } from '../../../../types/v2/review';

type GetAllPendingReviewsResponse = Review[] & {};

type UpdateReviewResponse = {
  message: string;
};

async function getAllPendingReviewsService(): Promise<GetAllPendingReviewsResponse> {
  return api.get(`/reviews/pending`);
}

async function patchReviewService(reviewId: string, approved: boolean): Promise<UpdateReviewResponse> {
  return api.patch(`/reviews/${reviewId}/approve`, { approved });
}

export { getAllPendingReviewsService, patchReviewService };
