import { api, apiBI } from './api';
import {
  CreateResponseDto,
  CreateResponseQuery,
  CreateResponseResponse,
  GetResponseByIdService,
  GetResponsesByPatientIdQuery,
  GetResponsesByPatientIdResponse,
  UpdateResponseDto,
  UpdateResponseEvidencesResponse,
  UpdateResponseQuery,
} from '../../../../types/v2/exam';

export type TFile = {
  id: string;
  attendance_id: string;
  type_id: string;
  custom_name: string;
  path: string;
  signed: boolean;
};

export type TFiles = {
  medical_reports: TFile[];
  exams: TFile[];
  orientations: TFile[];
  glasses_prescriptions: TFile[];
  medicine_prescriptions: TFile[];
};

export type TAttendance = {
  id: string;
  doctor: string;
  date: string;
  files: TFiles;
};

export type GetAttendanceLinkResponse = {
  url: string;
};

function getAttendancesService(patientId: string): Promise<TAttendance[]> {
  return apiBI.get(`/patient/${patientId}/grouped-docs`);
}

function getAttendanceLinkService(documentPath: string): Promise<string> {
  const path = documentPath;
  return apiBI.get(`/temporary-url`, { params: { path } });
}

function getResponseByIdService(id: string): Promise<GetResponseByIdService> {
  return api.get(`/v2/responses/${id}`);
}

function getResponsesByPatientIdService(patientId: string, query?: GetResponsesByPatientIdQuery): Promise<GetResponsesByPatientIdResponse> {
  return api.get(`/v2/responses`, { params: { patientId, ...query } });
}

function postCreateResponseService(body: CreateResponseDto, query?: CreateResponseQuery): Promise<CreateResponseResponse> {
  return api.post('/v2/responses', body, { params: query });
}

function patchUpdateResponseService(id: string, body: UpdateResponseDto, query?: UpdateResponseQuery): Promise<CreateResponseResponse> {
  return api.post(`/v2/responses/${id}`, body, { params: query });
}

function patchUpdateResponseEvidencesService(responseId: string, body: FormData): Promise<UpdateResponseEvidencesResponse> {
  return api.patch(`/v2/responses/${responseId}/evidences`, body);
}

function deleteResponseService(id: string) {
  return api.delete(`/v2/responses/${id}`);
}

export {
  getAttendancesService,
  getAttendanceLinkService,
  getResponseByIdService,
  getResponsesByPatientIdService,
  postCreateResponseService,
  patchUpdateResponseService,
  deleteResponseService,
  patchUpdateResponseEvidencesService,
};
