function downloadFile(url: string, filename?: string) {
  // Se o nome do arquivo não for fornecido, cria um com a data atual
  if (!filename) {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    filename = `${day}-${month}-${year} ${hours}-${minutes}`;
  }

  // Cria um elemento <a> para fazer o download
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  // Adiciona o link ao DOM e clica nele para iniciar o download
  document.body.appendChild(link);
  link.click();

  // Remove o link do DOM após o clique
  document.body.removeChild(link);
}

/**
 * Converte uma string base64 para um objeto File
 * @param base64 - String base64 para converter
 * @param filename - Nome do arquivo (opcional)
 * @param mimeType - Tipo MIME do arquivo (opcional, padrão: 'application/octet-stream')
 * @returns Objeto File criado a partir do base64
 */
function base64ToFile(base64: string, filename?: string, mimeType: string = 'application/octet-stream'): File {
  // Remove o prefixo de data URL se existir (ex: "data:image/png;base64,")
  const base64WithoutPrefix = base64.includes(',') ? base64.split(',')[1] : base64;

  // Decodifica a string base64
  const byteString = atob(base64WithoutPrefix);

  // Cria um array buffer para armazenar os bytes
  const arrayBuffer = new ArrayBuffer(byteString.length);

  // Cria uma view para manipular o array buffer
  const intArray = new Uint8Array(arrayBuffer);

  // Preenche o array com os bytes do base64
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  // Se o nome do arquivo não for fornecido, cria um com a data atual
  if (!filename) {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    filename = `arquivo_${day}-${month}-${year}_${hours}-${minutes}`;
  }

  // Extrai o tipo MIME do data URL se existir
  if (base64.includes('data:') && base64.includes(';base64,')) {
    const extractedMimeType = base64.split(';')[0].split(':')[1];
    if (extractedMimeType) {
      mimeType = extractedMimeType;
    }
  }

  // Cria um Blob com os dados
  const blob = new Blob([arrayBuffer], { type: mimeType });

  // Cria e retorna o objeto File
  return new File([blob], filename, { type: mimeType });
}

function arrayBufferToFile(buffer: ArrayBuffer, filename?: string, mimeType: string = 'application/octet-stream'): File {
  // Se o nome do arquivo não for fornecido, cria um com a data atual
  if (!filename) {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    filename = `arquivo_${day}-${month}-${year}_${hours}-${minutes}`;
  }

  // Cria um Blob com os dados
  const blob = new Blob([buffer], { type: mimeType });

  // Cria e retorna o objeto File
  return new File([blob], filename, { type: mimeType });
}

export { downloadFile, base64ToFile, arrayBufferToFile };
