import Pusher, { Channel } from 'pusher-js';

const pusher = new Pusher(process.env.PUSHER_APP_KEY, {
    cluster: process.env.PUSHER_CLUSTER
});

Pusher.logToConsole = process.env.NODE_ENV !== 'production';

function createChannel(channel: string): Channel {
    let _channel = pusher.channels.find(channel);
    if (!_channel?.subscribed) {
        _channel = pusher.subscribe(channel);
    }
    return _channel;
}

function removeChannel(channel: string) {
    pusher.unsubscribe(channel);
}

export { createChannel, removeChannel };