import { jwtDecode } from 'jwt-decode';
import { getAccessToken } from './cookies.util';
import { UserFromToken } from '../../../../types/v2/user';

export const getDecodedToken = (): UserFromToken => {
  const token = getAccessToken();
  if (!token) {
    return null;
  }
  return jwtDecode(token);
};
