import { api } from './api';

export type GetAllJobPositionsQuery = {
  search: string;
  lang: 'pt' | 'es' | 'en' | 'de' | 'fr' | 'it' | 'cn';
};

type GetAllJobPositionsResponse = {
  jobPositions: { key: string; label: string }[];
};

function getAllJobPositionsService(query: GetAllJobPositionsQuery): Promise<GetAllJobPositionsResponse> {
  return api.get(`/v2/settings/job-positions`, { params: query });
}

export { getAllJobPositionsService };
