import { z } from "zod";

/** Remove caracteres especiais, espaços e letras, mantendo apenas números */
const removeSpecialChars = (value: string) => value.replace(/[^\d]/g, '');

/** Validador para CPF, aceitando com ou sem pontuação */
export const zodCpf = z
  .string()
  .trim()
  .transform(removeSpecialChars)
  .refine(
    (value) => value.length === 11,
    "O CPF deve conter 11 dígitos numéricos"
  );

/** Validador para RNM/RNE no formato A123456 ou G1234567 */
export const zodRNM = z
  .string()
  .trim()
  .toUpperCase()
  .refine(
    (value) => /^[A-Z]\d{6,7}$/.test(value),
    "O RNM/RNE deve começar com uma letra maiúscula seguida de 6 ou 7 dígitos"
  );

/** Validador para SSN (Social Security Number), aceitando com ou sem hífens */
export const zodSocialSecurityNumber = z
  .string()
  .trim()
  .transform(removeSpecialChars)
  .refine(
    (value) => value.length === 9,
    "O SSN deve conter 9 dígitos numéricos"
  );

/** Validador para CNPJ, aceitando com ou sem pontuação */
export const zodCnpj = z
  .string()
  .trim()
  .transform(removeSpecialChars)
  .refine(
    (value) => value.length === 14,
    "O CNPJ deve conter 14 dígitos numéricos"
  );

/** Validador para DNI argentino, aceitando com ou sem pontuação */
export const zodArgentineDNI = z
  .string()
  .trim()
  .transform(removeSpecialChars)
  .refine(
    (value) => value.length === 8,
    "O DNI argentino deve conter 8 dígitos numéricos"
  );

/** Validador para Cédula de Ciudadanía colombiana */
export const zodColombianCC = z
  .string()
  .trim()
  .transform(removeSpecialChars)
  .refine(
    (value) => value.length >= 7 && value.length <= 10,
    "A Cédula de Ciudadanía colombiana deve conter entre 7 e 10 dígitos numéricos"
  );

/** Validador para CI uruguaia, aceitando com ou sem pontuação */
export const zodUruguayanCI = z
  .string()
  .trim()
  .transform(removeSpecialChars)
  .refine(
    (value) => value.length === 8,
    "A CI uruguaia deve conter 8 dígitos numéricos"
  );

/** Validador para CI boliviana */
export const zodBolivianCI = z
  .string()
  .trim()
  .transform(removeSpecialChars)
  .refine(
    (value) => value.length >= 7 && value.length <= 8,
    "A CI boliviana deve conter 7 ou 8 dígitos numéricos"
  );

export function formatDocumentByCountry(value?: string, countryCode?: string): string {
  if (!value) {
    return '';
  }

  const numbers = value.replace(/\D/g, '');

  if (!countryCode) {
    return numbers;
  }
  
  switch(countryCode) {
    case 'BRA':
      return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    case 'USA':
      return numbers.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    case 'ARG':
      return numbers.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2.$3');
    case 'URY':
      return numbers.replace(/(\d{1})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
    case 'COL':
      return numbers.replace(/(\d{3})(\d{3})(\d{3,4})/, '$1.$2.$3');
    case 'BOL':
      return numbers.replace(/(\d{2})(\d{3})(\d{2,3})/, '$1.$2.$3');
    default:
      return value;
  }
}

export function validateIdentifierByCountryUtil(identifier: string, countryCode: string) {
  if (countryCode === "BRA") {
    return zodCpf.safeParse(identifier).success || zodRNM.safeParse(identifier).success;
  }

  if (countryCode === "USA") {
    return zodSocialSecurityNumber.safeParse(identifier).success;
  }

  if (countryCode === "ARG") {
    return zodArgentineDNI.safeParse(identifier).success;
  }

  if (countryCode === "COL") {
    return zodColombianCC.safeParse(identifier).success;
  }

  if (countryCode === "URY") {
    return zodUruguayanCI.safeParse(identifier).success;
  }

  if (countryCode === "BOL") {
    return zodBolivianCI.safeParse(identifier).success;
  }

  return false;
}