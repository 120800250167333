import { jwtDecode } from 'jwt-decode';
import { getAccessToken } from './cookies.util';

type Ijwt = {
  id: string;
  username: string;
  name: string;
  companyId: string;
  companyName: string;
  companyType: string;
  visualAcuityWithAI?: boolean;
  modules: Array<string>;
  roles: Array<'admin' | 'employee' | 'doctor' | 'patient'>;
  root: boolean;
  exp: number;
  iat: number;
};

export const getDecodedToken = (): Ijwt => {
  return jwtDecode(getAccessToken());
};
