type TUser = {
  root: boolean;
  createdAt: string;
  address: TAddress;
  name: string;
  gender: string;
  updatedAt: string;
  birthdate: string;
  emails: Array<string>;
  cellphone: string;
  cpf: string;
  acceptedTerms: boolean;
  id: string;
  company: {
    roles: TUserRoles[];
    name: string;
    id: string;
    type: string;
    email: string;
    sso: {
      clientId: string;
      cloudPlataform: string;
      authority: string;
      secretKey: string;
      tenantId: string;
    } | null;
    status: string;
  };
  email: string;
  roles: Array<string>;

  pendencies?: 'NONE' | 'TELEMEDICINE';
  modulesIncluded: string[];
  jobPosition: string;
};

type TUserRoles = 'employee' | 'admin' | 'doctor';

type TAddress = {
  number: string;
  zipCode: string;
  city: string;
  neighbourhood: string;
  street: string;
  state: string;
  complement: string;
};

export enum ECookie {
  ACCESS_TOKEN = 'access_token',
  USER = 'user',
  ROLES = 'roles',
  VIEW_TYPE = 'view_type',
  COMPANY = 'company',
  ONBOARDING = 'onboarding',
}

export const setCookie = (key: ECookie, value: string, expires = 1) => {
  const date = new Date();
  date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
  document.cookie = `_bv_${key}=${value};expires=${date.toUTCString()};path=/`;
};

export const getCookie = (key: ECookie) => {
  const name = `_bv_${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (key: ECookie) => {
  document.cookie = `_bv_${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const isAuthenticated = (): boolean => {
  return Boolean(getCookie(ECookie.ACCESS_TOKEN) && getCookie(ECookie.USER));
};

export const setAccessToken = (accessToken: string) => {
  setCookie(ECookie.ACCESS_TOKEN, accessToken);
};

export const getAccessToken = (): string | null => {
  return getCookie(ECookie.ACCESS_TOKEN);
};

export const clearAuthCookies = () => {
  Object.values(ECookie).forEach((cookie) => {
    deleteCookie(cookie);
  });
};
