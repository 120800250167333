import { SelectOption } from '../../../../types/v2/global';
import { useEffect, useState } from 'react';
import { getAllJobPositionsService } from '../services/settings.service';

export function useJobPositionsForCombo(): {
  jobPositionsOptions: SelectOption[];
  jobPositions: { key: string; label: string }[];
  isFetching: boolean;
  promise: (search: string) => Promise<SelectOption[]>;
} {
  const [jobPositionsOptions, setjobPositionsOptions] = useState<SelectOption[]>([]);
  const [jobPositions, setJobPositions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchJobPositionsForCombo(search: string) {
    setIsFetching(true);
    try {
      const response = await getAllJobPositionsService({
        lang: 'pt',
        search,
      });

      const newJobPositionsOptions: SelectOption[] = response.jobPositions.map((unit) => ({
        label: unit.label,
        value: unit.key,
      }));

      setJobPositions(response.jobPositions);
      setjobPositionsOptions(newJobPositionsOptions);

      return newJobPositionsOptions;
    } catch (error) {
      return [] as SelectOption[];
    } finally {
      setIsFetching(false);
    }
  }

  return { jobPositionsOptions, jobPositions, isFetching, promise: fetchJobPositionsForCombo };
}
