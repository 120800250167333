import { BrazilFlagIcon, SpainFlagIcon, USAFlagIcon } from '../components/SvgIcons';
import { SelectOption } from '../../../../types/v2/global';
import { useState } from 'react';

const COUNTRY_OPTIONS: SelectOption[] = [
  {
    label: 'BR',
    value: 'BR',
    icon: <BrazilFlagIcon size={24} />,
  },
  {
    label: 'EUA',
    value: 'US',
    icon: <USAFlagIcon size={24} />,
  },
  {
    label: 'ESP',
    value: 'ES',
    icon: <SpainFlagIcon size={24} />,
  },
];

export function useCountiresForCombo(): { countries: SelectOption[] } {
  const [countries] = useState<SelectOption[]>(COUNTRY_OPTIONS);

  return {
    countries,
  };
}
