export function applyCpfMask(cpf: string) {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export function applyCnpjMask(cnpj: string) {
  return cnpj
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export function applyCellphoneMask(cellphone: string) {
  if (!cellphone) return '';
  return cellphone
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
}

export function applyMaskByBusinessIdentifierUtil(businessIdentifier: string, countryCode: string): string {
  if (!businessIdentifier) return '';

  switch (countryCode) {
    case 'BR':
      return businessIdentifier.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');

    case 'US':
      return businessIdentifier.replace(/^(\d{2})(\d{7})$/, '$1-$2');

    case 'ES':
      return businessIdentifier.replace(/^(\d{8})(\d{1})$/, '$1-$2');

    default:
      return businessIdentifier;
  }
}
