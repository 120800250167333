import { ECookie, getCookie, deleteCookie, setCookie } from './cookies.util';

function saveScreenCalibration(pixelsPerCm: number) {
  setCookie(ECookie.SCREEN_CALIBRATION, JSON.stringify({ pixelsPerCm }));
}

function getScreenCalibration() {
  const screenCalibration = getCookie(ECookie.SCREEN_CALIBRATION);
  return screenCalibration ? JSON.parse(screenCalibration) : null;
}

function removeScreenCalibration() {
  deleteCookie(ECookie.SCREEN_CALIBRATION);
}

function isScreenCalibrationSaved(): boolean {
  return !!getCookie(ECookie.SCREEN_CALIBRATION);
}

function getScreenSize() {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  return { width: screenWidth, height: screenHeight };
}

function getScreenSizeInPixel() {
  const screenSize = getScreenSize();
  const screenWidth = screenSize.width;
  const screenHeight = screenSize.height;
  return { width: screenWidth, height: screenHeight };
}

function convertCMToPixel(valueInCentimeters: number) {
  const cookie = getCookie(ECookie.SCREEN_CALIBRATION);
  const proportion = cookie ? JSON.parse(cookie) : null;
  if (!proportion) throw new Error('Proporção não encontrada');
  return valueInCentimeters * proportion.pixelsPerCm;
}

function convertPixelToCM(valueInPixels: number) {
  const cookie = getCookie(ECookie.SCREEN_CALIBRATION);
  const proportion = cookie ? JSON.parse(cookie) : null;
  if (!proportion) throw new Error('Proporção não encontrada');
  return valueInPixels / proportion.pixelsPerCm;
}

function convertPixelToMM(valueInPixels: number) {
  const cookie = getCookie(ECookie.SCREEN_CALIBRATION);
  const proportion = cookie ? JSON.parse(cookie) : null;
  if (!proportion) throw new Error('Proporção não encontrada');
  return (valueInPixels / proportion.pixelsPerCm) * 10;
}

function convertPixelToInch(valueInPixels: number) {
  const cookie = getCookie(ECookie.SCREEN_CALIBRATION);
  const proportion = cookie ? JSON.parse(cookie) : null;
  if (!proportion) throw new Error('Proporção não encontrada');
  return (valueInPixels / proportion.pixelsPerCm) * 0.0393701;
}

function convertMMToPixel(valueInMM: number) {
  const cookie = getCookie(ECookie.SCREEN_CALIBRATION);
  const proportion = cookie ? JSON.parse(cookie) : null;
  if (!proportion) throw new Error('Proporção não encontrada');
  return (valueInMM / 10) * proportion.pixelsPerCm;
}

function convertInchToPixel(valueInInch: number) {
  const cookie = getCookie(ECookie.SCREEN_CALIBRATION);
  const proportion = cookie ? JSON.parse(cookie) : null;
  if (!proportion) throw new Error('Proporção não encontrada');
  return valueInInch * proportion.pixelsPerCm * 0.0393701;
}
export {
  convertCMToPixel,
  convertPixelToCM,
  getScreenCalibration,
  getScreenSize,
  getScreenSizeInPixel,
  isScreenCalibrationSaved,
  removeScreenCalibration,
  saveScreenCalibration,
  convertPixelToMM,
  convertPixelToInch,
  convertMMToPixel,
  convertInchToPixel,
};
