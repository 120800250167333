import {
  BrazilFlagIcon,
  USAFlagIcon,
  SpainFlagIcon,
  GermanyFlagIcon,
  UruguaiFlagIcon,
  ColombiaFlagIcon,
  ArgentinaFlagIcon,
  BoliviaFlagIcon,
} from '../components/SvgIcons';

const mapContractTypeDescription: Record<string, string> = {
  benefit: 'Benefício',
  occupational: 'Ocupacional',
  optics: 'Ótica',
  partner: 'Parceiro',
};

const mapUnitTypeToBadgeVariant: Record<string, string> = {
  benefit: 'info',
  occupational: 'secondary',
  optics: 'warning',
  partner: 'destructive',
};

const mapFeatureToDescription: Record<string, string> = {
  '1': 'Triagem',
  '2': 'Teste de acuidade',
  '3': 'Teste de Ishihara',
  '4': 'Funcionalidade acesso ao médico do trabalho',
  '5': 'Desconto em óticas',
  '6': 'Concierge (suporte do time de Customer Success)',
  '7': 'Teleoftalmologia',
  '8': 'Plano de comunicação',
  '9': 'Conteúdo especial de saúde',
  '10': 'Consultório in company',
  '11': 'Renovação de receita de óculos',
  '12': 'Teste de acuidade para perto',
};

const mapDescriptionToFeature: Record<string, string> = {
  Triagem: '1',
  'Teste de acuidade': '2',
  'Teste de acuidade para perto': '12',
  'Teste de Ishihara': '3',
  'Funcionalidade acesso ao médico do trabalho': '4',
  'Desconto em óticas': '5',
  'Concierge (suporte do time de Customer Success)': '6',
  Teleoftalmologia: '7',
  'Plano de comunicação': '8',
  'Conteúdo especial de saúde': '9',
  'Consultório in company': '10',
  'Renovação de receita de óculos': '11',
};

const mapExcelErrorTypeToDescription: Record<string, string> = {
  required: 'Célula é obrigatório e nao deve conter celulas vazias.',
  invalid: 'Célula possui valores que não estão entre os aceitos.',
  type: 'Célula possui tipos de dado não aceitos por essa coluna.',
};

const mapStatusToDescription: Record<string, string> = {
  pending: 'Pendente',
  approved: 'Aprovado',
  rejected: 'Rejeitado',
  canceled: 'Cancelado',
  active: 'Ativo',
  inactive: 'Inativo',
  waiting: 'Aguardando',
  renewed: 'Realizado',
  completed: 'Concluído',
  'pre-register': 'Pré-registro',
};

const mapRoleToDescription: Record<string, string> = {
  admin: 'Administrador',
  doctor: 'Médico Ocupacional',
  employee: 'Colaborador',
  partner: 'Parceiro',
  patient: 'Paciente',
};

const mapCountryCodeToFlag: Record<string, React.ReactNode> = {
  BRA: <BrazilFlagIcon size={24} />,
  USA: <USAFlagIcon size={24} />,
  ESP: <SpainFlagIcon size={24} />,
  GER: <GermanyFlagIcon size={24} />,
  URU: <UruguaiFlagIcon size={24} />,
  COL: <ColombiaFlagIcon size={24} />,
  ARG: <ArgentinaFlagIcon size={24} />,
  BOL: <BoliviaFlagIcon size={24} />,
};

export {
  mapContractTypeDescription,
  mapFeatureToDescription,
  mapDescriptionToFeature,
  mapExcelErrorTypeToDescription,
  mapStatusToDescription,
  mapRoleToDescription,
  mapUnitTypeToBadgeVariant,
  mapCountryCodeToFlag,
};
