import axios, { AxiosInstance } from 'axios';
import { clearAuthCookies, ECookie, getCookie } from '../utils/cookies.util';

// TODO: Está sendo utilizado?

const BASE_URL: string | undefined = process.env.BV_API_URL;

const api: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});

api.interceptors.request.use(function (config) {
  const ACCESS_TOKEN: string | null = getCookie(ECookie.ACCESS_TOKEN);

  if (ACCESS_TOKEN) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    });
  }

  return config;
});

api.interceptors.response.use(null, function (err) {
  console.log('interceptor error: ', JSON.stringify(err, null, 2));
  if (err.response && [401, 403].includes(+err.response.status)) {
    clearAuthCookies();
    window.location.href = '/login';
  } else if (err.response && err.response.status === 400 && err.response && err.response.data) {
    return Promise.reject(err.response.data);
  } else if (err.response && err.response.status === 422 && err.response.data && err.response.data.errors) {
    const errors = Object.keys(err.response.data.errors).reduce((acc, key) => {
      acc = acc.concat(err.response.data.errors[key]);
      return acc;
    }, []);
    return Promise.reject({ message: errors.join(' | ') });
  }

  return Promise.reject('Não foi possível realizar essa ação, caso o problema persista, contate o suporte.');
});

export default api;
