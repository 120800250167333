import { Unit } from '../../../../types/v2/unit';
import { api } from './api';
import { ApiDeleteResponseBase, ApiPaginatedResponse, ApiQueryBase } from '../../../../types/v2/api';
import { CompanyStatus } from '../../../../types/v2/company';

export type UnitInList = {
  _id: string;
  rootCompanyId: string;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  doctorCount: number;
  adminCount: number;
  employeeCount: number;
  totalUsers: number;
  enabledContracts: Array<{
    contractId: string;
    enabledPlans: Array<string>;
    _id: string;
  }>;
  __v: number;
  rootCompanyName: string;
  subsidiaryName: string;
  subsidiaryId: string;
  parentUnitName: string;
  parentUnitId: string;
  enabledContractDetails: Array<{
    contractDetails: {
      _id: string;
      companyId: string;
      name: string;
      status: string;
      type: string;
      properties: {
        enabledPlans: Array<string>;
      };
      createdAt: string;
      updatedAt: string;
      __v: number;
    };
    enabledPlansDetails: Array<{
      _id: string;
      name: string;
      features: Array<string>;
      type: string;
      status: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
    }>;
  }>;
};

export type GetAllUnitsQuery = ApiQueryBase & {
  subsidiaryId?: string;
  parentUnitId?: string;
  lean?: boolean;
};

type GetAllUnitsResponse = ApiPaginatedResponse<UnitInList>;

export type GetUnitsByCompanyIdQuery = ApiQueryBase;

export type GetUnitsByCompanyIdResponse = ApiPaginatedResponse<UnitInList>;

export type GetUnitByIdResponse = {
  _id: string;
  rootCompanyId: string;
  parentUnitId: any;
  subsidiaryId: {
    _id: string;
    parentCompanyId: string;
    businessIdentifier: string;
    countryCode: string;
    address: {
      city: string;
      complement: string;
      country: string;
      neighbourhood: string;
      number: string;
      state: string;
      street: string;
      zipCode: string;
      location: {
        type: string;
        coordinates: Array<number>;
      };
    };
    corporateName: string;
    name: string;
    status: string;
    isSSOEnabled: boolean;
    brand: string;
    inviteValidationType: string;
    rating: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  enabledContracts: Array<{
    contractId: string;
    enabledPlans: Array<string>;
    _id: string;
  }>;
  rootCompanyName: string;
  enabledContractDetails: Array<{
    contractDetails: {
      _id: string;
      companyId: string;
      name: string;
      status: string;
      type: string;
      properties: {
        enabledPlans: Array<string>;
      };
      createdAt: string;
      updatedAt: string;
      __v: number;
    };
    enabledPlansDetails: Array<{
      _id: string;
      name: string;
      features: Array<string>;
      type: string;
      status: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
    }>;
  }>;
};

export type CreateUnitDto = {
  name: string;
  rootCompanyId: string;
  subsidiaryId?: string;
  parentUnitId?: string;
};

type CreateUnitResponse = Unit;

export type UpdateUnitDto = {
  name?: string;
  subsidiaryId?: string;
  parentUnitId?: string;
  status?: CompanyStatus;
  enabledContracts?: {
    contractId: string;
    enabledPlans?: string[];
  }[];
};

type UpdateUnitResponse = {};

type DeleteUnitResponse = ApiDeleteResponseBase;

function postCreateUnitService(body: CreateUnitDto): Promise<CreateUnitResponse> {
  return api.post(`/v2/companies/units`, body);
}

function getAllUnitsService(query: GetAllUnitsQuery): Promise<GetAllUnitsResponse> {
  return api.get(`/v2/companies/units`, { params: query });
}

function getUnitByIdService(unitId: string): Promise<GetUnitByIdResponse> {
  return api.get(`/v2/companies/units/${unitId}`);
}

function patchUpdateUnitService(unitId: string, body: UpdateUnitDto): Promise<UpdateUnitResponse> {
  return api.patch(`/v2/companies/units/${unitId}`, body);
}

function deleteUnitService(unitId: string): Promise<DeleteUnitResponse> {
  return api.delete(`/v2/companies/units/${unitId}`);
}

export { getAllUnitsService, postCreateUnitService, getUnitByIdService, patchUpdateUnitService, deleteUnitService };
