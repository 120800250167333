import { I18nextProvider, useTranslation } from 'react-i18next';
import api from './api/config';
import * as cookieUtils from './utils/cookies.util';
import i18n from './utils/internacionalization.util';
import { toastHandler } from './utils/toast.util';
import emitter from './utils/events.util';
import * as dump from './utils/dump.util';
import * as token from './utils/token.util';
import * as mask from './utils/mask.util';
import * as file from './utils/file.util';
import * as request from './utils/request.util';
import * as device from './utils/device.util';

export default {
  api,
  cookies: { ...cookieUtils },
  dump,
  token,
  mask,
  file,
  request,
  device,

  //events
  emitter,

  //internacionalization
  internacionalization: {
    useTranslation,
    I18nextProvider,
    i18n,
  },

  //dump
  toastHandler,
};
