import { ApiPaginatedResponse, ApiQueryBase } from '../../../../types/v2/api';
import { Company } from '../../../../types/v2/company';
import { ExamResponse } from '../../../../types/v2/exam';
import { Address } from '../../../../types/v2/global';
import { Plan } from '../../../../types/v2/plan';
import { User, UserStatus } from '../../../../types/v2/user';
import { api } from './api';

export type GetAllUsersQuery = ApiQueryBase & {
  companyId?: string;
  unitId?: string;
  status?: UserStatus;
};

export type GetAllUsersResponse = User & {
  rootCompany: Company;
  responses: ExamResponse[];
  plan: Plan;
};

type GetUserByIdResponse = User;

type GetContractsByUserIdResponse = {
  _id: string;
  rootCompanyId: string;
  oldId: any;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  enabledContracts: Array<{
    contractId: string;
    enabledPlans: Array<string>;
    _id: string;
  }>;
  __v: number;
  subsidiaryName: any;
  subsidiaryId: any;
  parentUnitName: any;
  parentUnitId: any;
  enabledContractsDetails: Array<{
    contractDetails: {
      _id: string;
      companyId: string;
      name: string;
      status: string;
      type: string;
      createdAt: string;
      updatedAt: string;
      properties: {
        enabledPlans: Array<string>;
        startDate: string;
        endDate: string;
        autoRenew: boolean;
      };
      __v: number;
    };
    enabledPlansDetails: Array<{
      _id: string;
      planId: string;
      name: string;
      features: Array<string>;
      type: string;
      status: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
    }>;
  }>;
};

export type GetUsersBySessionQuery = ApiQueryBase & {
  status?: UserStatus;
  globalSearch?: boolean;
};

export type GetUsersBySessionResponse = ApiPaginatedResponse<
  User & {
    rootCompany: Company;
    responses: ExamResponse[];
    plan: Plan;
  }
>;

function getUsersBySessionService(query: GetUsersBySessionQuery): Promise<GetUsersBySessionResponse> {
  return api.get(`/v2/users/self/users`, { params: query });
}

function getUserByIdService(userId: string): Promise<GetUserByIdResponse> {
  return api.get(`/v2/users/${userId}`);
}

function getContractsByUserIdService(userId: string): Promise<GetContractsByUserIdResponse> {
  return api.get(`/v2/users/${userId}/contracts`);
}

export { getContractsByUserIdService, getUserByIdService, getUsersBySessionService };
