import emitter from "./events.util";

export type TNotification = {
  message: string;
  type: "success" | "error" | "info" | "warning";
  title?: string;
};

export const toastHandler = (notification: TNotification) => {
  emitter.emit("@bv/middleware<-notify", notification);
};
