import emitter from './events.util';

export type TNotification = {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  title?: string;
};

export type TNotificationPromise = {
  loadingText: string;
  successMessage: string;
  errorMessage?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
  promise: Promise<any>;
};

export const toastHandler = (notification: TNotification) => {
  emitter.emit('@bv/middleware<-notify', notification);
};

export const toastPromiseHandler = (notification: TNotificationPromise) => {
  return new Promise<any>((resolve, reject) => {
    emitter.emit('@bv/middleware<-notify-promise', { ...notification, resolve, reject });
  });
};
