import { ApiDeleteResponseBase, ApiPaginatedResponse, ApiQueryBase } from '../../../../types/v2/api';
import { Company, CompanyStatus, ECompanyValidationType, SSO } from '../../../../types/v2/company';
import { Address } from '../../../../types/v2/global';
import { api } from './api';

export type CreateSubsidiaryDto = {
  businessIdentifier: string;
  countryCode: string;
  address: Address;
  corporateName: string;
  name: string;
  status: CompanyStatus;
  inviteValidationType?: ECompanyValidationType;
};

type CreateSubsidiaryResponse = Company;

type GetAllSubsidiariesQuery = ApiQueryBase & {};

type GetAllSubsidiariesResponse = ApiPaginatedResponse<CompanyWithUnitAndUserCount>;

export type CompanyWithUnitAndUserCount = Company & {
  unitsCount: number;
  usersCount: number;
};

type GetSubsidiaryByIdResponse = Company & {};

export type UpdateSubsidiaryDto = {
  businessIdentifier?: string;
  countryCode?: string;
  address?: Address;
  corporateName?: string;
  name?: string;
  status?: CompanyStatus;
  inviteValidationType?: ECompanyValidationType;
  isSSOEnabled?: boolean;
  sso?: SSO;
};

type UpdateSubsidiaryResponse = Company & {};

type DeleteSubsidiaryResponse = ApiDeleteResponseBase & {};

function postCreateSubsidiaryService(body: FormData): Promise<CreateSubsidiaryResponse> {
  return api.post(`/v2/companies/subsidiaries`, body);
}

function getAllSubsidiariesService(query: GetAllSubsidiariesQuery): Promise<GetAllSubsidiariesResponse> {
  return api.get(`/v2/companies/subsidiaries`, { params: query });
}

function getSubsidiaryByIdService(subsidiaryId: string): Promise<GetSubsidiaryByIdResponse> {
  return api.get(`/v2/companies/subsidiaries/${subsidiaryId}`);
}

function patchUpdateSubsidiaryService(subsidiaryId: string, body: FormData): Promise<UpdateSubsidiaryResponse> {
  return api.patch(`/v2/companies/subsidiaries/${subsidiaryId}`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

function deleteSubsidiaryService(subsidiaryId: string): Promise<DeleteSubsidiaryResponse> {
  return api.delete(`/v2/companies/subsidiaries/${subsidiaryId}`);
}

export { postCreateSubsidiaryService, getAllSubsidiariesService, getSubsidiaryByIdService, patchUpdateSubsidiaryService, deleteSubsidiaryService };
