import { toastHandler } from './toast.util';

function getInitials(name: string): string {
  if (!name) {
    return '';
  }
  const parts = name.trim().split(/\s+/);

  const initials = parts
    .slice(0, 2)
    .map((part) => part[0].toUpperCase())
    .join('');

  return initials;
}

function validarCPF(cpf: string): boolean {
  // Remove caracteres não numéricos
  cpf = cpf.replace(/[^\d]/g, '');

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais, como "111.111.111-11"
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let digitoVerificador1 = 11 - (soma % 11);
  if (digitoVerificador1 >= 10) digitoVerificador1 = 0;

  // Verifica o primeiro dígito verificador
  if (digitoVerificador1 !== parseInt(cpf.charAt(9))) {
    return false;
  }

  // Calcula o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  let digitoVerificador2 = 11 - (soma % 11);
  if (digitoVerificador2 >= 10) digitoVerificador2 = 0;

  // Verifica o segundo dígito verificador
  if (digitoVerificador2 !== parseInt(cpf.charAt(10))) {
    return false;
  }

  return true;
}

function sortArray(array, key = 'created_at') {
  const sortedArray = array.sort((a, b) => new Date(a[key]).getTime() + new Date(b[key]).getTime());
  return sortedArray;
}

function abbreviationName(fullName = '-'): string {
  let nameSplited = fullName
    .trim()
    .replace(/ d?[aeo]s? /, ' ')
    .split(' ');

  if (nameSplited.length > 2) {
    nameSplited = [nameSplited[0], nameSplited[nameSplited.length - 1]];
  }

  const abbreviation = nameSplited
    .map((word) => word[0])
    .join('')
    .toUpperCase();

  return abbreviation;
}

function getOnlyFirstName(fullName = '-'): string {
  const names = fullName.split(' ');
  return names[0];
}

function arrayRange(start: number, stop: number, step: number): number[] {
  return Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);
}

const calculePriceWithDiscount = (price: number, discount: number): number => {
  const finalPrice = price - price * discount;
  return finalPrice;
};

const isInteger = (number: number): boolean => {
  return number === Math.floor(number);
};

function debounce<T extends (...args: any[]) => void>(func: T, delay: number = 1000): (...args: Parameters<T>) => void {
  let timeoutId;
  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

function copyToClipboard(text: string, successMessage?: string): void {
  navigator.clipboard.writeText(text);
  if (successMessage) toastHandler({ type: 'info', message: successMessage });
}

function hasObjectChangedUtil<T>(previousObject: T, currentObject: T): boolean {
  for (const key in currentObject) {
    if (currentObject[key] !== previousObject[key]) {
      return true; // Alteração detectada
    }
  }

  // Nenhuma alteração encontrada
  return false;
}

export {
  getInitials,
  validarCPF,
  sortArray,
  abbreviationName,
  getOnlyFirstName,
  arrayRange,
  calculePriceWithDiscount,
  isInteger,
  debounce,
  copyToClipboard,
  hasObjectChangedUtil,
};
