import { SelectOption } from '../../../../types/v2/global';
import { useState } from 'react';

const TYPE_OPTIONS: SelectOption[] = [
  {
    label: 'Benefício',
    value: 'benefit',
  },
  {
    label: 'Parceira',
    value: 'partner',
  },
  {
    label: 'Ótica',
    value: 'optic',
  },
];

export function useCompanyTypesForCombo(): { companyTypes: SelectOption[] } {
  const [companyTypes] = useState<SelectOption[]>(TYPE_OPTIONS);

  return {
    companyTypes,
  };
}
