function downloadFile(url: string, filename?: string) {
  // Se o nome do arquivo não for fornecido, cria um com a data atual
  if (!filename) {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    filename = `${day}-${month}-${year} ${hours}-${minutes}`;
  }

  // Cria um elemento <a> para fazer o download
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  // Adiciona o link ao DOM e clica nele para iniciar o download
  document.body.appendChild(link);
  link.click();

  // Remove o link do DOM após o clique
  document.body.removeChild(link);
}

export { downloadFile };
